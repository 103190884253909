import { render, staticRenderFns } from "./CyberboardR4.vue?vue&type=template&id=fbd0edc0&scoped=true&"
import script from "./CyberboardR4.vue?vue&type=script&lang=ts&"
export * from "./CyberboardR4.vue?vue&type=script&lang=ts&"
import style0 from "./CyberboardR4.vue?vue&type=style&index=0&id=fbd0edc0&lang=scss&scoped=true&"
import style1 from "./CyberboardR4.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbd0edc0",
  null
  
)

export default component.exports